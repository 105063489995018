/*
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-01-21 16:31:01
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-08-03 14:31:49
 */
export const overColums = [
  {
    title: '大区',
    dataIndex: 'bigAreaName',
    key: 'bigAreaName',
    align: 'center',
  },
  {
    title: '事务所',
    dataIndex: 'firmName',
    key: 'firmName',
    align: 'center',
  },
  {
    title: '经销商',
    dataIndex: 'dealerName',
    key: 'dealerName',
    align: 'center',
    scopedSlots: {
      customRender: 'dealerName'
    }
  },
  {
    title: '流水号',
    dataIndex: 'limitCode',
    key: 'limitCode',
    align: 'center',
    scopedSlots: {
      customRender: 'limitCode'
    }
  },
  {
    title: '额度名称',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
  },
  {
    title: '额度金额',
    dataIndex: 'limitAmount',
    key: 'limitAmount',
    align: 'center',
    scopedSlots: {
      customRender: 'amount'
    }
  },
  {
    title: '逾期金额',
    dataIndex: 'usedAmount',
    key: 'usedAmount',
    align: 'center',
    scopedSlots: {
      customRender: 'amount'
    }
  },
  {
    title: '逾期天数',
    dataIndex: 'overdueDays',
    key: 'overdueDays',
    align: 'center',
    scopedSlots: {
      customRender: 'overdueDays'
    }
  },
  {
    title: '额度类型',
    dataIndex: 'limitType',
    key: 'limitType',
    align: 'center',
    scopedSlots: {
      customRender: 'limitType'
    }
  },
  {
    title: '有效时间',
    dataIndex: 'validityStart',
    key: 'validityStart',
    align: 'center',
    scopedSlots: { customRender: 'validityStart' }
  },
  {
    title: '创建人',
    dataIndex: 'createUser',
    key: 'createUser',
    align: 'center',
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    align: 'center',
  },
]
